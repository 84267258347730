import React, { useState, useRef, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import TipTapEditor from "../TipTapEditor";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { api } from "../../store";

const DraftSourcesPanel = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "calc(30% - 16px)", // Subtracting half of the spacing
  minWidth: "calc(30% - 16px)",
  maxWidth: "calc(30% - 16px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  overflow: "auto",
}));

const DraftSourcesPanelComponent = ({
  projectId,
  userArchiveIDs,
  fetchProjectData,
  rowData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState("requirements");
  const reqsRef = useRef(null);
  const selectedItem = useSelector((state) => state.selectedItem);
  const itemsStatus = useSelector((state) => state.itemsStatus);

  const { sourcesToDisplay } = useMemo(() => {
    const statusVersion = itemsStatus.find(
      (status) => status.uuid === selectedItem?.uuid
    )?.version;
    let version;
    let sources;

    
    // console.log(statusVersion);


    if (statusVersion !== undefined) {
      sources = selectedItem?.sources?.[statusVersion];
      console.log(sources);
    } else {
      sources = selectedItem?.sources;
    }

    return { sourcesToDisplay: sources };
  }, [selectedItem, itemsStatus]);

  function getFileNameFromPath(path, start_time) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const foundItem = rowData.find((item) => item.id === path);
      return foundItem ? foundItem.name : path;
    } else return path;
  }

  const handleArchiveFile = async (path, start_time) => {
    try {
      let foundItem = rowData.find((item) => item.id === path);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contentType = response.data.fileType;

      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSourceExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <DraftSourcesPanel>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view toggle"
        sx={{ width: "100%", height: "3rem" }}
      >
        <ToggleButton
          value="requirements"
          aria-label="requirements"
          sx={{
            flex: 1,
            textAlign: "center",
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          <ChecklistRoundedIcon style={{ marginRight: "1rem" }} />
          Requirements
        </ToggleButton>
        <ToggleButton
          value="sources"
          aria-label="sources"
          sx={{
            flex: 1,
            textAlign: "center",
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          <FormatQuoteRoundedIcon style={{ marginRight: "1rem" }} />
          Sources
        </ToggleButton>
      </ToggleButtonGroup>

      {view === "sources" ? (
        <Box style={{ height: "100%", overflowY: "auto" }}>
          {sourcesToDisplay && sourcesToDisplay.length > 0 ? (
            [...sourcesToDisplay]
              .sort((a, b) => (a.uuid > b.uuid ? 1 : -1))
              .map((source, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === source.uuid}
                  onChange={handleSourceExpansion(source.uuid)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon />}
                    aria-controls={`panel-${source.uuid}-content`}
                    id={`panel-${source.uuid}-header`}
                  >
                    <Typography variant="primaryText">
                      {source.section_title ||
                        `[${source.uuid}] ` +
                          getFileNameFromPath(source.source_path)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="primaryText"
                      className="typography-content"
                      dangerouslySetInnerHTML={{
                        __html: source.extract,
                      }}
                    />
                    <br />
                    <br />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="primaryText">
                        Source:{" "}
                        {getFileNameFromPath(source.source_path) ||
                          "No path available"}
                      </Typography>
                      <LaunchRoundedIcon
                        onClick={() =>
                          handleArchiveFile(
                            source.source_path,
                            source.start_time
                          )
                        }
                        style={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
          ) : (
            <ListItem>
              <ListItemText primary="No sources available." />
            </ListItem>
          )}
        </Box>
      ) : selectedItem.requirements ? (
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          <TipTapEditor
            rteRef={reqsRef}
            projectId={projectId}
            archiveIds={userArchiveIDs.map((item) => item.id)}
            refreshItems={fetchProjectData}
            showToolbar={false}
            displayItem="requirements"
          />
        </Box>
      ) : (
        <ListItem>
          <ListItemText primary="No requirements available." />
        </ListItem>
      )}
    </DraftSourcesPanel>
  );
};

export default DraftSourcesPanelComponent;
