import React, { useState } from "react";
import { FileUploaderButton, FileUploaderItem, Dropdown } from "@carbon/react";
import "./FileUploader.scss";

const FileUploader = (props) => {
  const [isFileNumberExceededStatus, setFileNumberExceededStatus] =
    useState(false);

  const handleFileUpload = (event) => {
    setFileNumberExceededStatus(false);
    const fileList = event.target.files;

    const newFiles = Array.from(fileList);
    if (props.filesAndStatus.length + newFiles.length <= props.maxFiles) {
      const newFilesWithStatus = newFiles.map((file) => {
        return {
          file,
          status: "edit",
          tag: null,
        };
      });

      const updatedFilesAndStatus = [
        ...props.filesAndStatus,
        ...newFilesWithStatus,
      ];

      props.updateFileUploaderStatus(updatedFilesAndStatus);
    } else {
      setFileNumberExceededStatus(true);
    }
  };

  const handleFileRemove = (removedFile) => {
    if (props.filesAndStatus.length <= props.maxFiles) {
      setFileNumberExceededStatus(false);
    }

    props.updateFileUploaderStatus(
      props.filesAndStatus.filter((fs) => fs.file !== removedFile)
    );
  };

  const handleTagChange = (index, selectedTag) => {
    const updatedFilesAndStatus = [...props.filesAndStatus];
    updatedFilesAndStatus[index] = {
      ...updatedFilesAndStatus[index],
      tag: selectedTag,
    };
    props.updateFileUploaderStatus(updatedFilesAndStatus);
  };

  return (
    <div className="container">
      {props.uploadError && (
        <span
          style={{
            display: "block",
            color: "#da1e28",
            fontSize: "var(--text-medium)",
          }}
        >
          Oops something went wrong during upload, please check the
          requirements. If the issue persists contact: support@xpub.ai
        </span>
      )}
      {isFileNumberExceededStatus && (
        <div>
          <span
            style={{
              display: "block",
              color: "#da1e28",
              fontSize: "var(--text-medium)",
            }}
          >
            Limit exceeded: upload fewer than {props.maxFiles} files at once.
          </span>
        </div>
      )}
      {props.uploadErrorMessages.map((error) => (
        <div>
          {" "}
          <span
            style={{
              display: "block",
              color: "#da1e28",
              fontSize: "var(--text-medium)",
            }}
          >
            {error}
          </span>
          <br></br>
        </div>
      ))}
      <br></br>
      <FileUploaderButton
        accept={[
          ...props.acceptTypes,
          ...(props.allowAudioFiles ? [".mp3", ".mp4", ".mpeg"] : []),
        ]}
        labelText="Add Files"
        buttonKind="secondary"
        multiple
        size="lg"
        disableLabelChanges
        onChange={(evt) => handleFileUpload(evt)}
      />
      <br></br>

      <div style={{ width: "100%" }}>
        {props.filesAndStatus.map((fileStatus, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              gap: "2%",
              maxWidth: "100%",
              margin: "1rem 0.1rem 1rem 0",
            }}
          >
            <FileUploaderItem
              key={fileStatus.file.name}
              name={fileStatus.file.name}
              status={fileStatus.status}
              onDelete={() => handleFileRemove(fileStatus.file)}
              style={{
                border: "1px solid #ccc",
                borderRadius: "var(--border-radius)",
                padding: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minWidth: "78%",
                fontWeight: "normal",
                fontSize: "14px", // Consistent font size for all file names
              }}
            />
            {props.displayTags && props.tagOptions && (
              <Dropdown
                id={`tag-dropdown-${index}`}
                ariaLabel="Tag Dropdown"
                items={props.tagOptions}
                itemToString={(item) => (item ? item.label : "")}
                selectedItem={
                  fileStatus.tag
                    ? props.tagOptions.find((tag) => tag.id === fileStatus.tag)
                    : null
                }
                onChange={({ selectedItem }) =>
                  handleTagChange(index, selectedItem.id)
                }
                label="Select a tag"
                style={{
                  minWidth: "19%",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploader;




// import React, { useState, useCallback } from "react";
// import { 
//   Button, Typography, Box, Paper, Grid, 
//   Chip, IconButton, LinearProgress, Tooltip,
//   Dialog, DialogTitle, DialogContent, DialogActions,
//   List, ListItem, ListItemIcon, ListItemText
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import DeleteIcon from "@mui/icons-material/Delete";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import LabelIcon from "@mui/icons-material/Label";
// import { FormControl, Select, MenuItem } from "@mui/material";
// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const StyledDropzone = styled(Box)(({ theme }) => ({
//   border: `2px dashed ${theme.palette.primary.main}`,
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(4),
//   textAlign: "center",
//   cursor: "pointer",
//   transition: "background-color 0.3s ease",
//   "&:hover": {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

// const FileUploader = (props) => {
//   const [isFileNumberExceededStatus, setFileNumberExceededStatus] = useState(false);
//   const [openTagDialog, setOpenTagDialog] = useState(false);
//   const [currentFileIndex, setCurrentFileIndex] = useState(null);

//   const handleFileUpload = (event) => {
//     setFileNumberExceededStatus(false);
//     const fileList = event.target.files;

//     const newFiles = Array.from(fileList);
//     if (props.filesAndStatus.length + newFiles.length <= props.maxFiles) {
//       const newFilesWithStatus = newFiles.map((file) => ({
//         file,
//         status: "edit",
//         tag: null,
//         progress: 0,
//       }));

//       const updatedFilesAndStatus = [...props.filesAndStatus, ...newFilesWithStatus];
//       props.updateFileUploaderStatus(updatedFilesAndStatus);

//       // Simulate upload progress
//       newFilesWithStatus.forEach((file, index) => {
//         simulateFileUpload(props.filesAndStatus.length + index);
//       });
//     } else {
//       setFileNumberExceededStatus(true);
//     }
//   };

//   const simulateFileUpload = (index) => {
//     const interval = setInterval(() => {
//       props.updateFileUploaderStatus((prevFiles) => {
//         const newFiles = [...prevFiles];
//         if (newFiles[index]) {
//           newFiles[index] = {
//             ...newFiles[index],
//             progress: Math.min(newFiles[index].progress + 10, 100),
//           };
//           if (newFiles[index].progress === 100) {
//             clearInterval(interval);
//           }
//         } else {
//           clearInterval(interval);
//         }
//         return newFiles;
//       });
//     }, 500);
//   };

//   const handleFileRemove = (removedFile) => {
//     if (props.filesAndStatus.length <= props.maxFiles) {
//       setFileNumberExceededStatus(false);
//     }
//     props.updateFileUploaderStatus(
//       props.filesAndStatus.filter((fs) => fs.file !== removedFile)
//     );
//   };

//   const handleTagChange = (index, selectedTag) => {
//     const updatedFilesAndStatus = [...props.filesAndStatus];
//     updatedFilesAndStatus[index] = {
//       ...updatedFilesAndStatus[index],
//       tag: selectedTag,
//     };
//     props.updateFileUploaderStatus(updatedFilesAndStatus);
//     setOpenTagDialog(false);
//   };

//   const openTagSelector = (index) => {
//     setCurrentFileIndex(index);
//     setOpenTagDialog(true);
//   };

//   return (
//     <Box sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
//       {props.uploadError && (
//         <Typography variant="body2" sx={{ mb: 2, color: "error.main" }}>
//           Oops something went wrong during upload, please check the
//           requirements. If the issue persists contact: support@xpub.ai
//         </Typography>
//       )}
//       {isFileNumberExceededStatus && (
//         <Typography variant="body2" sx={{ mb: 2, color: "error.main" }}>
//           Limit exceeded: upload fewer than {props.maxFiles} files at once.
//         </Typography>
//       )}
//       {props.uploadErrorMessages.map((error, index) => (
//         <Typography key={index} variant="body2" sx={{ mb: 1, color: "error.main" }}>
//           {error}
//         </Typography>
//       ))}

//       <StyledDropzone>
//         <input
//           type="file"
//           onChange={handleFileUpload}
//           accept={props.acceptTypes.join(',')}
//           multiple
//           style={{ display: 'none' }}
//           id="file-input"
//         />
//         <label htmlFor="file-input">
//           <Box>
//             <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
//             <Typography>Click to select files or drag and drop them here</Typography>
//           </Box>
//         </label>
//       </StyledDropzone>

//       <Grid container spacing={2} sx={{ mt: 3 }}>
//         {props.filesAndStatus.map((fileStatus, index) => (
//           <Grid item xs={12} key={index}>
//             <Paper 
//               elevation={3}
//               sx={{
//                 p: 2,
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: 2,
//                 borderRadius: 'var(--border-radius)',
//                 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
//               }}
//             >
//               <InsertDriveFileIcon sx={{ color: 'primary.main' }} />
//               <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
//                 <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
//                   {fileStatus.file.name}
//                 </Typography>
//                 <LinearProgress variant="determinate" value={fileStatus.progress} sx={{ mt: 1 }} />
//               </Box>
//               {props.displayTags && props.tagOptions && (
//                 <FormControl sx={{ minWidth: 120 }} size="small">
//                   <Select
//                     value={fileStatus.tag || ''}
//                     onChange={(event) => handleTagChange(index, event.target.value)}
//                     displayEmpty
//                     inputProps={{ 'aria-label': 'Select tag' }}
//                   >
//                     <MenuItem value="" disabled>
//                       <em>Select tag</em>
//                     </MenuItem>
//                     {props.tagOptions.map((option) => (
//                       <MenuItem key={option.id} value={option.id}>
//                         {option.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               )}
//               <Tooltip title="Remove file">
//                 <IconButton
//                   onClick={() => handleFileRemove(fileStatus.file)}
//                   size="small"
//                   sx={{ color: 'error.main' }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </Tooltip>
//             </Paper>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default FileUploader;

